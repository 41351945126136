.fs-tab-custom-container {
  display: flex;
  flex-direction: column;
  position: relative;
  //   background: #f1f1f1;
  word-break: break-all;
  //   border: 1px solid rgba(0, 0, 0, 0.274);
}

.fs-tab-custom-bloc-tabs {
  display: flex;
}
.fs-tab-custom-tabs {
  text-align: center;
  width: 232px;
  height: 48px;
  background: #e2e2e2;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  border-radius: 12px 12px 0 0;
}

// .fs-tab-custom-tabs:not(:last-child) {
//   border-right: 1px solid rgba(0, 0, 0, 0.274);
// }

.fs-tab-custom-active-tabs {
  background: #f6fbff;
  border-radius: 12px 12px 0 0;
  //   border-bottom: 1px solid transparent;
}

// .fs-tab-custom-active-tabs::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: -5px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: calc(100% + 2px);
//   height: 5px;
//   background: rgb(88, 147, 241);
// }

.fs-tab-custom-button {
  font-family: "IBM Plex Sans Thai";
  border: none;
}
.fs-tab-custom-content-tabs {
  flex-grow: 1;
}
.fs-tab-custom-content {
  background: #f6fbff;
  padding: 24px;
  width: 100%;
  height: 100%;
  display: none;
}
.fs-tab-custom-content h2 {
  padding: 0px 0 5px 0px;
}
.fs-tab-custom-content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.fs-tab-custom-content p {
  width: 100%;
  height: 100%;
}
.fs-tab-custom-active-content {
  display: block;
}
