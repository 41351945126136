.custom-input-and-dropdown:hover {
  border-color: #000;
}

.custom-input-number {
  border: 0px;
  border-radius: 28px;
  margin: 1px;
  background-color: transparent;
  font-weight: 500;
  color: #191919;
  height: 52px;
  padding-bottom: 2px;
  text-align: right;
  width: 50%;
  padding-right: 12px;

  &:active {
    border: 0px;
    outline: none;
  }
  &:focus {
    border: 0px;
    outline: none;
  }
}
