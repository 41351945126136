.custom-y-axis-label {
  font-size: 9.704px !important;
  color: #ff5733 !important;
}

.y-axis-labels {
  font-size: 9.704px !important;
  color: #ff5733 !important;
}
.apexcharts-toolbar {
  display: none !important;
}
