.top-img-container {
  height: 130vw;
}

.farms-overview-head {
  margin-top: -140px;
}

.farms-headTitle {
  font-size: 20px;
}

.manage-farm-main-box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.manage-farm-main-box2 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.notification-icon {
  color: #ffff;
}

@media only screen and (max-width: 350px) {
  .manage-farm-container2 {
    flex-direction: column;
    gap: 10px;
  }
  .top-img-container {
    height: 150vw;
  }

  .farms-top-box {
    margin-left: 0px;
    margin-right: 0px;
  }

  .farms-top-box-text {
    font-size: 15px;
  }

  .favorite-icon-container {
    flex-direction: column;
    align-items: center;
  }

  .manage-farm-container {
    flex-direction: column;
    align-items: center;
  }

  .manage-farm-main-box {
    height: 510px;
  }

  .farms-overview-head {
    margin-top: -90px;
  }

  .topIcon1 {
    right: 2.3rem;
  }
  .pig-price-container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 750px) {
  .topIcon1 {
    top: 15%;
    right: 4.5rem;
  }

  .farms-overview-head {
    margin-top: -230px;
  }

  .farms-headTitle {
    font-size: 50px;
  }

  .farms-headTitle-container {
    margin-left: 35px;
  }

  .farms-top-box-text {
    font-size: 35px;
  }

  .farms-overview-head-text {
    font-size: 40px;
    margin-left: 2rem;
  }

  .add-farms-btn {
    font-size: 25px;
  }

  .farms-manage-text {
    font-size: 30px;
    margin-left: 2rem;
  }

  .notification-icon {
    height: 50px !important;
    width: 50px !important;
  }

  .top-img-container {
    height: 120vw;
  }

  .responsive-tablet-headTitle {
    font-size: 37.5px;
  }

  .responsive-tablet-SecondHeadTitle {
    font-size: 30px;
  }

  .responsive-tablet-title {
    font-size: 24px;
  }

  .responsive-tablet-SecondTitle {
    font-size: 21px;
  }

  .responsive-tablet-text {
    font-size: 18px;
  }
}

@media only screen and (min-width: 350px) {
  .apexcharts-title-text,
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    font-size: 12px !important;
  }
  .custom-chart-container {
    height: 300px !important;
  }
}

@media only screen and (min-width: 750px) {
  .apexcharts-title-text,
  .apexcharts-xaxis-label,
  .apexcharts-yaxis-label {
    font-size: 18px !important;
  }
  .custom-chart-container {
    height: 600px !important;
  }
}
