.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: #000000;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  background: #68c184;
}

.swiper-pagination-horizontal {
  padding-top: 28px;
  padding-bottom: 16px;
}

.swiper-pagination {
  position: relative !important;
}

.swiper-wrapper {
  height: auto;
}

.swiper {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
