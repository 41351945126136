.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-day-passive {
  pointer-events: none;
  padding: 10px 10px !important;
  span {
    span {
      color: transparent !important;
    }
  }
}

.custom-day {
  height: 3.5em !important;
}

.custom-select-day {
  background-color: #ddf3e4 !important;
  left: 6px !important;
  right: 6px !important;
  ~ span {
    border-radius: 100% !important;
    left: 4.5px !important;
    right: 4.5px !important;
    background-color: #68c184 !important;
    span {
      color: white !important;
    }
  }
}

.custom-day-number {
  color: transparent;
  color: white !important;
  font-weight: normal;
  font-size: medium;
}

.custom-prev-btn {
  grid-area: button1;
  position: absolute;
  right: 60px;
}

.custom-next-btn {
  grid-area: button2;
  right: 15px;
  position: absolute;
}
