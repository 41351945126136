.btg-drop-shadow-table {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 20px;
}

.shadow-box-custom {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.5);
}

.triangle-custom {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #fff;

  box-shadow: "0px 0px 10px 0px #68C184 inset";
  -webkit-transform: translate(-45%, 30%) rotate(45deg);
  -moz-transform: translate(-45%, 30%) rotate(45deg);
  -ms-transform: translate(-45%, 30%) rotate(45deg);
  transform: translate(-55%, 80%) rotate(0deg);

  background-color: transparent;

  left: 50%;
  right: 50%;
  bottom: 0;
  filter: drop-shadow(0px 0px 15px #68c184);
}

.shadow-box-custom {
  box-shadow: 0 10px 25px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.5);
}
