.selling-line-vertical-input {
  height: 100%;
  width: 1px;
  top: 50%;
  background-color: #d9ebfe;
  border-radius: 100%;
  padding-top: 6px;
  padding-bottom: 8px;
  align-items: center;
  align-self: center;
  align-content: center;
}
