@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;700&display=swap");
@import "react-date-range/dist/styles.css"; // main style file
@import "react-date-range/dist/theme/default.css";

:root {
  --primary-color: green;
}

body {
  margin: 0;
  font-family: IBM Plex Sans Thai;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App,
.main-container {
  height: 100% !important;
}

.bg-cont {
  background: #4882c8;
}

.App :has(.bg-parent) code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.radio-confirmation {
  display: flex;
  align-items: flex-start;
  text-align: left;
  column-gap: 2rem;
}

// .betagro-datepicker .MuiOutlinedInput-notchedOutline {
//   width: 100%;
//   border-color: #3777BC;
//   border-radius: 28px;
// }

// .betagro-datepicker .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
//   width: 100%;
//   background-color: #F7FBFF;
//   border-radius: 28px
// }

// .betagro-datepicker {
//   .MuiInputBase-root {
//     width: 100%;
//   }
// }

// .betagro-select .MuiInputLabel-root {
//   background: white;
//   padding-right: 8px;
// }

.betagro-autocomplete .MuiInputBase-root {
  border-radius: 28px;
}

// .MuiTextField-root :not(.Mui-focused):focus fieldset {
//   border-color: #3777BC !important;
// }

.error {
  color: red !important;
}

// .MuiMenu-list li {
//   border-bottom: 1PX solid #fffbfb;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 12px 16px;
//   gap: 10px;
//   width: 328px;
//   height: 50px;
//   background: #FFFFFF;
//   box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
// }

// .MuiList-root {
//   /* Set a fixed height and overflow to enable scrolling */
//   height: 200px;
//   overflow-y: scroll;
// }

.MuiList-root::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.MuiList-root::-webkit-scrollbar-track {
  background-color: #d9ebfe;
}

/* Handle */
.MuiList-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3777bc;
}

.onboardingPageSlider .MuiPaper-root {
  box-shadow: none;
  border-radius: unset;
  padding: unset;
}

.onboardingPageSlider li.dot.selected {
  background: #68c184;
  border-radius: 8px;
  width: 36px;
  height: 10px;
}

.onboardingPageSlider li.dot {
  width: 10px;
  height: 10px;
  margin: 0px 10px;
  background: #d9d9d9;
}

.onboardingPageSlider .carousel .control-dots {
  position: absolute;
  bottom: 17%;
  display: flex;
  z-index: 2;
  width: inherit;
  justify-content: flex-start;
  padding: 0px 25px;
}

.thumbs-wrapper {
  display: none;
}

.container {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

// TODO : REMOVE important consider updating in theme or required move to specific style of the component
.bg-white,
.bg-white-important {
  background-color: white !important;
}

html,
body,
#root,
.App,
.main-container {
  height: 100% !important;
}

.MuiButton-root:disabled {
  background-color: #e2e2e2 !important;
  cursor: not-allowed;
}

.bottom-sheet {
  position: fixed;
  transform: translateY(540px);
  transition: ease 0.5s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #ffffff;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 28px 28px 0 0;

  &.open {
    transform: translateY(0px);
  }
}

.margin-top-4 {
  margin-top: 1rem !important;
}

.scrollbarCustom ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  position: absolute;
}

.scrollbarCustom ::-webkit-scrollbar-thumb {
  background: #3777bc;
  border-radius: 10px;
}

.scrollbarCustom ::-webkit-scrollbar-track {
  margin: 10px;
}

.tableContainer {
  direction: ltr;
  -webkit-transform: rotateX(180deg);
}

.content {
  // transform: rotateX(180deg);
  // -ms-transform: rotateX(180deg);
  margin-left: 10px;
  margin-bottom: 10px;
  -webkit-transform: rotateX(180deg);
}

.housePerformanceContainer {
  height: calc(100vh - 300px);
  overflow: auto;
  direction: rtl;
}

.houseperformanceTableRow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.totalOfMedicineValue fieldset,
.totalOfMedicineValue .MuiOutlinedInput-root {
  border-radius: 28px 0px 0px 28px !important;
  border-right: #3777bc;
}

.toalOfMedicineDropdown fieldset,
.toalOfMedicineDropdown .MuiOutlinedInput-root {
  border-radius: 0px 28px 28px 0px !important;
  border-left: 1px solid #3777bc;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrStartEdge,
.rdrEndEdge {
  background-color: #68c184;
  // border-radius: 50%
}

// .rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content
// {
//   background-color: #68C184 !important;
//   border-radius: 50%
// }

// .rs-calendar-table-cell-selected .rs-calendar-table-cell-content
// {
//   background-color: #68C184 !important;
//   border-radius: 50%;
// }

.rdrInRange {
  background: #ddf3e4 !important;
}

.rdrDayNumber span {
  color: #1d2429 !important;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-stack-item .rs-btn-close {
  display: none;
}

.rs-picker .rs-btn {
  border: none;
}

.rs-calendar-table-cell-in-range:before {
  border-radius: 10px;
}

/* Styling for the modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Styling for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrWeekDay {
  font-size: 0;
}

.rdrWeekDay:first-letter {
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.rdrNextButton {
  grid-area: button2;
  position: absolute;
  margin-left: 76%;
}

.rdrPprevButton {
  grid-area: button1;
  margin-left: 64%;

  position: absolute;
}

.rdrMonthAndYearPickers {
  grid-area: date;
}

.rdrMonthAndYearWrapper {
  height: 80px;
  padding-top: 0;
  justify-content: unset !important;
  display: grid;
  grid-template-areas: "date button1 button2";
}

.rdrWeekDays {
  height: 15px;
}

.MuiDialog-root button.Mui-selected {
  background-color: #34407b !important;
  color: white !important;
}
