@import "./variable";

// .App {
//   text-align: center;
// }

@font-face {
  font-family: "DM Sans";
  src: url("../assets/fonts/DMSans-Font.ttf") format("truetype");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Mui-disabled {
  color: #979797;
}

//Breakpoints Responsive
// X-Small
@media (min-width: $--width-xs) {
  .App-header {
    background-color: purple;
    display: flex;
  }
}

// Small
@media (min-width: $--width-sm) {
  .App-header {
    background-color: aqua;
    display: flex;
  }
}

// Medium
@media (min-width: $--width-md) {
  .App-header {
    background-color: blue;
    display: flex;
  }
}

// Large
@media (min-width: $--width-lg) {
  .App-header {
    background-color: green;
    display: flex;
  }
}

// Extra large
@media (min-width: $--width-xl) {
  .App-header {
    background-color: yellow;
    display: flex;
  }
}

// Extra extra large
@media (min-width: $--width-xxl) {
  .App-header {
    background-color: orange;
    display: flex;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  color: #ffffff !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-track {
  color: #68c184 !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  color: #68c184 !important;
}

.MuiSvgIcon-root.MuiSelect-icon {
  color: #3777bc;
}

// TODO: Move this to component style or handle this with themeing. Using Important afftect other fields
// .MuiOutlinedInput-notchedOutline{
//    border-color: #97BFEA !important;
// }

.custom-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 4px 0px;
}

.MuiOutlinedInput-root {
  border-radius: 28px !important;
  background-color: #f7fbff;
}

.search-medicine .MuiAutocomplete-root .MuiInputLabel-root:not(.Mui-focused) {
  padding-left: 40px !important;
}

.search-medicine .MuiAutocomplete-root.Mui-focused fieldset {
  border-color: #3777bc !important;
}

.search-medicine .MuiAutocomplete-root .MuiInputBase-input {
  padding-left: 40px !important;
}

.recharts-layer.recharts-label-list {
  display: none;
}
.add-farm-button {
  margin: auto !important;
  display: flex !important;
}
.btn-pdf {
  margin-left: auto !important;
}

.line-vertical {
  height: 100%;
  width: 3px;
  background-color: #3777bc;
  border-radius: 99px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
